import Button from "components/Button";
import AdminLayout from "layouts/AdminLayout";
import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import ApiService from "services/ApiService";
import { handleErrors } from "services/HelperMethods";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";

export default function GameConfig() {
    const apiService = new ApiService();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const [isWorking, setIsWorking] = useState(false);
    const inputRef = useRef(null);

    async function emptyCheck() {     
        if (!username || !password || !cpassword) 
        {
            toast("All fileds are required!");
            return;
        }
        else if(password!= cpassword)
        {
            toast("Password is not matching!");
            return;
        }  
        else if(password.length>6)
        {
            toast("The password must be at least 6 characters!");
        }

        var UserRole=2;
        var TwoFactorAuthenticate =false;
       let data = {username,password,UserRole,TwoFactorAuthenticate}
       //console.log("data",data);
       let result = await apiService.createuser(data);
       //console.log(result);
       if(result.data==true)
       {
        toast("User saved!");
        setUsername("");
        setPassword("");
        setCpassword("");
       }
       else
       {
        toast("User already exist!");
        return;
       }
    }
    return (
        <AdminLayout>
            <div className="config-page">
                <h2>User Register</h2>               
                    <div>
                        <div className="config-section">
                            <label>User Email<span className="req">*</span></label>
                            <input
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        placeholder="Enter User Name"
                                        className="form-control"
                                        value={username}
                            />    
                           
                        </div>
                        <div className="config-section">
                            <label>Password<span className="req">*</span></label>
                            <input       
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder="Enter Password"
                                        type="password"
                                        className="form-control"
                                       value={password}
                            />                       
                        </div>
                        <div className="config-section">
                            <label>Confirm Password<span className="req">*</span></label>
                            <input
                                         onChange={(e) =>
                                             setCpassword(e.target.value)
                                            }                                      
                                                                                                                         
                                        
                                        placeholder="Enter Confirm Password"
                                        type="password"
                                        className="form-control"
                                        value={cpassword}
                            />                       
                        </div>
                        <div className="config-section text-right mt-1">
                            <Button
                                type="submit"
                                isWorking={isWorking}
                                className="btn btn-primary"
                                onClick={ (e) => {
                                    emptyCheck();
                                                                        
                                }}
                            >
                                SAVE
                            </Button>
                        </div>
                    </div>               
            </div>
        </AdminLayout>
    );

   
}