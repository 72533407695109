/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import ApiService from "services/ApiService";
import moment from "moment";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import Loading from "components/Loading";
import AdminLayout from "layouts/AdminLayout";
import { CSVLink } from "react-csv";
import Table from "../components/Table";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import ReactTooltip from "react-tooltip";
import { Dropdown } from "react-bootstrap";

const Dashboard = (props) => {
  const apiService = new ApiService();
  const startDate = moment().add(-7, "d").toDate();
  const endDate = moment().add(0, "d").toDate();
  const [GameType, setGameType] = useState(5 as any);
  const [analyticData, setAnalyticData] = useState([]);
  const [funnelData, setFunnelData] = useState([]);
  const [byPlayerReport, setByPlayerReport] = useState([]);
  const [dailyReport, setDailyReport] = useState("" as any);
  const [isWorking, setIsWorking] = useState(false);
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const [searchToDate, setSearchToDate] = useState(toDate);
  const [searchFromDate, setSearchFromDate] = useState(fromDate);
  const [dailyReportSearchDate, setDailyReportSearchDate] = useState(
    moment().toDate()
  );

  history.pushState(null, "", location.href);
  window.onpopstate = function (event) {
    history.go(1);
  };

  const [filter, setFilter] = useState("All");
  useEffect(() => {
    getReports();
    if (!localStorage.getItem("jwt")) {
      props.history.push("Login");
    }
  }, []);

  useEffect(() => {
    getDailyReport();
  }, [dailyReportSearchDate]);

  function getDailyReport() {
    var today = {};
    let data = { today: dailyReportSearchDate, GameType };
    console.log(data, "daily report");

    apiService
      .getDailyReport(data)
      .then((data: any) => {
        data = data.data;
        setDailyReport(data);
        console.log(data, "setDailyReport");
      })
      .catch((err) => {});
  }

  function getReports() {
    setSearchFromDate(fromDate);
    setSearchToDate(toDate);
    setIsWorking(true);

    //var dateRange = { fromDate, toDate };
    let data = { fromDate, toDate, GameType };
    console.log(data, "filter details");

    apiService.getAnalytics(data).then((data: any) => {
      data = data.data;
      if (!data) return;

      setAnalyticData(data);
      console.log("analytics", data);
    });

    apiService.getFunnelData(data).then((data: any) => {
      data = data.data;
      setFunnelData(data);
    });

    apiService.playerCount(data).then((data: any) => {
      data = data.data;
      setByPlayerReport(data);
    });

    setIsWorking(false);
  }

  function getAnalyticData() {
    const columns = [
      {
        name: "Day",
        selector: "day",
        sortable: true,
        format: (d) => {
          return moment(d.day).local().format("ll");
        },
      },
      {
        name: (
          <Tippy content="Total games count of players played.">
            <div>Total Game Played</div>
          </Tippy>
        ),
        selector: "totalGamePlayed",
        sortable: true,
      },
      {
        name: (
          <Tippy content="Total revenue for.">
            <div>Total Revenue</div>
          </Tippy>
        ),
        selector: "totalGamePlayers",
        sortable: true,
        format: (d) => {
          return "$" + d.totalGamePlayers;
        },
      },
      {
        name: (
          <Tippy content="Total rake collected from players.">
            <div>Total Rake</div>
          </Tippy>
        ),
        selector: "totalRake",
        sortable: true,
        format: (d) => {
          return "$" + d.totalRake;
        },
      },
      {
        name: (
          <Tippy content="Sum of all Jackpot after deducting rake.">
            <div>Total Jackpot</div>
          </Tippy>
        ),
        selector: "totalJackpot",
        sortable: true,
        format: (d) => {
          return "$" + d.totalJackpot;
        },
      },
      {
        name: (
          <Tippy content="The count of players who deposited money for first time.">
            <div>First Time Depositors</div>
          </Tippy>
        ),
        selector: "uniquePlayerEntered",
        sortable: true,
      },
      {
        name: (
          <Tippy content="Total of deposited money by first time depositors.">
            <div>First Time Deposits</div>
          </Tippy>
        ),
        selector: "uniquePlayerEntered",
        sortable: true,
        format: (d) => {
          return "$" + d.uniquePlayerEntered;
        },
      },
      {
        name: (
          <Tippy content="The count of players who deposited more than [2] times.">
            <div>Repeat Depositors</div>
          </Tippy>
        ),
        selector: "repeatPlayers",
        sortable: true,
      },
      {
        name: (
          <Tippy content="Total of repeatedly deposited money by repeat depositors.">
            <div>Repeat Deposits</div>
          </Tippy>
        ),
        selector: "repeatPlayers",
        sortable: true,
        format: (d) => {
          return "$" + d.repeatPlayers;
        },
      },
    ];

    return <DataTable columns={columns} data={analyticData} />;
  }

  function getFunnelData() {
    const columns = [
      {
        name: "Day",
        selector: "day",
        sortable: true,
        format: (d) => {
          return moment(d.day).local().format("ll");
        },
      },
      {
        name: (
          <Tippy content="The count of players who started the game.">
            <div>Players Start Game</div>
          </Tippy>
        ),
        selector: "totalGamePlayers",
        sortable: true,
      },
      {
        name: (
          <Tippy content="The count of players who disconnected while playing the game.">
            <div>Players Disconnected</div>
          </Tippy>
        ),
        selector: "playersDisconnected",
        sortable: true,
      },
      {
        name: (
          <Tippy content="The count of players who played the game untill the end.">
            <div>Players played full game</div>
          </Tippy>
        ),
        selector: "playersPlayFullGame",
        sortable: true,
      },
      {
        name: (
          <Tippy content="The count of players who played more than one game.">
            <div>Players played another game</div>
          </Tippy>
        ),
        selector: "playMoreThanOneGame",
        sortable: true,
      },
      {
        name: (
          <Tippy content="The count of players who use chat to comunicate.">
            <div>Players Use Game Chat</div>
          </Tippy>
        ),
        selector: "playerUseGameChat",
        sortable: true,
      },
    ];

    return <DataTable columns={columns} data={funnelData} />;
  }

  let reportingAndAnalyticsHeaders = [
    { label: "Date", key: "date" },
    { label: "reCAPTCHA Score - 0.1 - 0.4", key: "reCaptchaScoreLessThan5" },
    { label: "reCAPTCHA Score - 0.5 - 0.7", key: "reCaptchaScoreLessThan8" },
    { label: "reCAPTCHA Score - 0.8 - 1", key: "reCaptchaScoreMoreThan8" },
    { label: "New Users", key: "newUsers" },
    { label: "Repeat Users", key: "repeatUsers" },
    { label: "Daily Active Users", key: "dailyActiveUsers" },
    { label: "Monthly Active Users", key: "monthlyActiveUsers" },
    { label: "£11 - £15", key: "incomeLessThan16" },
    { label: "£16 - £20", key: "incomeLessThan20" },
    { label: "£20+", key: "incomeMoreThan20" },
    { label: "£1 - £5", key: "spendUser1to5" },
    { label: "£6 - £10", key: "spendUser6to10" },
    { label: "£11 - £15", key: "spendUser11to15" },
    { label: "£16 - £20", key: "spendUser16to20" },
    { label: "£21+", key: "spendUser21" },
    { label: "Total £", key: "totalRevenue" },
    { label: "S %", key: "totalRevenueSingle" },
    { label: "M %", key: "totalRevenueMulti" },
    { label: "Genre Rev - Hollywood", key: "hollywoodRevenue" },
    { label: "Genre Rev - Music", key: "musicRevenue" },
    { label: "Genre Rev - 00", key: "thousandsRevenue" },
    { label: "Genre Rev - 90", key: "nintiesRevenue" },
    { label: "Genre Rev - 80", key: "eightiesRevenue" },
    { label: "1 - 10", key: "LostGamesLessThan10" },
    { label: "11 - 20", key: "LostGamesLessThan20" },
    { label: "21 - 30", key: "LostGamesLessThan30" },
    { label: "31+", key: "LostGamesGreaterThan31" },
    { label: "Anwered Correcly % - 0 - 2", key: "correctlyAnsweredLessThan2" },
    { label: "Anwered Correcly % - 2 - 4", key: "correctlyAnsweredLessThan4" },
    { label: "Anwered Correcly % - 4 - 6", key: "correctlyAnsweredLessThan6" },
    { label: "Anwered Correcly % - 6 - 8", key: "correctlyAnsweredLessThan8" },
    { label: "Anwered Correcly % - 8 - 10", key: "correctlyAnsweredMoreThan8" },
    { label: "Win Ratio % - 0 - 5", key: "gamesWonLessThan6" },
    { label: "Win Ratio % - 6 - 10", key: "gamesWonLessThan11" },
    { label: "Win Ratio % - 11 - 25", key: "gamesWonLessThan26" },
    { label: "Win Ratio % - 26 - 50", key: "gamesWonLessThan51" },
    { label: "Win Ratio % - 50+", key: "gamesWonMoreThan51" },
    { label: "Frequency Played - 2 - 5", key: "playedLessThan6Games" },
    { label: "Frequency Played - 6 - 10", key: "playedLessThan11Games" },
    { label: "Frequency Played - 11+", key: "playedMoreThan11Games" },
  ];

  let data = dailyReport || [];

  let columnsTable = React.useMemo(
    () => [
      { Header: "Date", accessor: "date" },
      {
        Id: "1",
        Header: "New Users",
        accessor: "newUsers",
        name: "Players who played the game for first time.",
      },
      {
        Header: "Repeat Users",
        accessor: "repeatUsers",
        name: "Players who play the game repatly.",
      },
      {
        Header: "Daily Users",
        accessor: "dailyActiveUsers",
        name: "Players who play game daily.",
      },
      {
        Header: "Monthly Users",
        accessor: "monthlyActiveUsers",
        name: "Players who have played in the previous 30 days",
      },
      {
        Header: "reCAPTCHA Score %",
        name: "Google Captcha Score by % ",
        columns: [
          {
            Header: "0.1 - 0.4",
            accessor: "reCaptchaScoreLessThan5",
            name: "Google Captcha Score 0.1 to 0.5",
          },
          {
            Header: "0.5 - 0.7",
            accessor: "reCaptchaScoreLessThan8",
            name: "Google Captcha Score 0.5 to 0.7",
          },
          {
            Header: "0.8 - 1.0",
            accessor: "reCaptchaScoreMoreThan8",
            name: "Google Captcha Score 0.8 to 1.0",
          },
        ],
      },
      {
        Id: "1",
        Header: "Spend/user %",
        name: "Player spending by %",
        columns: [
          {
            Id: "1",
            Header: "£1 - £5",
            accessor: "spendUser1to5",
            name: "Players who spend £1 to £5",
          },
          {
            Id: "1",
            Header: "£6 - £10",
            accessor: "spendUser6to10",
            name: "Players who spend £6 to £10",
          },
          {
            Id: "1",
            Header: "£11 - £15",
            accessor: "spendUser11to15",
            name: "Players who spend £11 to £15",
          },
          {
            Id: "1",
            Header: "£16 - £20",
            accessor: "spendUser16to20",
            name: "Players who spend £16 to £20",
          },
          {
            Id: "1",
            Header: "£21+",
            accessor: "spendUser21",
            name: "Players who spend more than £21",
          },
        ],
      },
      {
        Header: "Revenue",
        name: "Daily revenue",
        columns: [
          {
            Header: "Total £",
            accessor: "totalRevenue",
            name: "Total of daily revenue.",
          },
          {
            Header: "S %",
            accessor: "totalRevenueSingle",
            name: "Total of singleplayer revenue.",
          },
          {
            Header: "M %",
            accessor: "totalRevenueMulti",
            name: "Total of multiplayer revenue.",
          },
        ],
      },
      {
        Id: "1",
        Header: "Genre Rev (Multi + Single) £",
        name: "Revenue by game gener.",
        columns: [
          {
            Id: "1",
            Header: "Hollywood",
            accessor: "hollywoodRevenue",
            name: "Total revenue of hollywood.",
          },
          {
            Id: "1",
            Header: "Music",
            accessor: "musicRevenue",
            name: "Total revenue of music.",
          },
          {
            Id: "1",
            Header: "00",
            accessor: "thousandsRevenue",
            name: "Total revenue of 00.",
          },
          {
            Id: "1",
            Header: "90",
            accessor: "nintiesRevenue",
            name: "Total revenue of 90.",
          },
          {
            Id: "1",
            Header: "80",
            accessor: "eightiesRevenue",
            name: "Total revenue of 80.",
          },
        ],
      },
      {
        Header: "Single Player Jackpot # Games Before Won",
        name: "Game count of a player, played before win a jackpot.",
        columns: [
          {
            Header: "1 - 10",
            accessor: "lostGamesLessThan10",
            name: "Lost game count 1 to 10",
          },
          {
            Header: "11 - 20",
            accessor: "lostGamesLessThan20",
            name: "Lost game count 11 to 20",
          },
          {
            Header: "21 - 30",
            accessor: "lostGamesLessThan30",
            name: "Lost game count 21 to 30",
          },
          {
            Header: "31+",
            accessor: "lostGamesGreaterThan31",
            name: "Lost game more than 31",
          },
        ],
      },
      {
        Header: "Questions answer correctly % (M & S)",
        name: "Players who answered the questions correctly in singleplayer & multiplayer games",
        columns: [
          {
            Header: "0 - 2",
            accessor: "correctlyAnsweredLessThan2",
            name: "Correctly answered quection count 1 to 2",
          },
          {
            Header: "2 - 4",
            accessor: "correctlyAnsweredLessThan4",
            name: "Correctly answered quection count 2 to 4",
          },
          {
            Header: "4 - 6",
            accessor: "correctlyAnsweredLessThan6",
            name: "Correctly answered quection count 4 to 6",
          },
          {
            Header: "6 - 8",
            accessor: "correctlyAnsweredLessThan8",
            name: "Correctly answered quection count 6 to 8",
          },
          {
            Header: "8 - 10",
            accessor: "correctlyAnsweredMoreThan8",
            name: "Correctly answered quection count 8 to 10",
          },
        ],
      },
      {
        Id: "1",
        Header: "Win Ratio: % of games played people win",
        name: "Players Win Ratio by %",
        columns: [
          {
            Id: "1",
            Header: "0 - 5",
            accessor: "gamesWonLessThan6",
            name: "Players who won game less than 5",
          },
          {
            Id: "1",
            Header: "6 - 10",
            accessor: "gamesWonLessThan11",
            name: "Players who won game 6 to 10",
          },
          {
            Id: "1",
            Header: "11 - 25",
            accessor: "gamesWonLessThan26",
            name: "Players who won game 11 to 25",
          },
          {
            Id: "1",
            Header: "26 - 50",
            accessor: "gamesWonLessThan51",
            name: "Players who won game 26 to 50",
          },
          {
            Id: "1",
            Header: "50+",
            accessor: "gamesWonMoreThan51",
            name: "Players who won game more than 50",
          },
        ],
      },
      // {
      //   Header: "Frequency Played",
      //   columns: [
      //     { Header: "1", accessor: "playedOneGame" },
      //     { Header: "2 - 5", accessor: "playedLessThan6Games" },
      //     { Header: "6 - 10", accessor: "playedLessThan11Games" },
      //     { Header: "11+", accessor: "playedMoreThan11Games" },
      //   ],
      // },
    ],
    []
  );

  return (
    <AdminLayout>
      <div>
        <Loading isWorking={isWorking} />

        <div></div>

        <div className="row justify-content-between pb-3">
          <div  className="header-tab">
          <select className="form-control" style={{maxWidth:"250px"}}>
              <option>All Games</option>
              <option>SinglePlayer-5050</option>
              <option>MultiPlayer-5050</option>
              <option>Play with Friends Multiplayer</option>        
            </select>
          </div>
          <div className="col">
            
            <button
              className={filter == "All" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("All");
                setGameType(5);
              }}
            >
              All
            </button>
            <button
              className={filter == "Hollywood Quiz" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("Hollywood Quiz");
                setGameType(0);
              }}
            >
              Hollywood Quiz
            </button>
            <button
              className={filter == "80s" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("80s");
                setGameType(1);
              }}
            >
              80s
            </button>
            <button
              className={filter == "90s" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("90s");
                setGameType(2);
              }}
            >
              90s
            </button>
            <button
              className={filter == "00s" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("00s");
                setGameType(3);
              }}
            >
              00s
            </button>
            <button
              className={filter == "Music" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("Music");
                setGameType(4);
              }}
            >
              Music
            </button>
          </div>
        </div>
        <div className="row justify-content-between pb-3">
          <div className="col-auto">
            <div className="row justify-content-end">
              <div className="col-auto">
                <label className="mr-2">From</label>
                <DatePicker
                  selected={fromDate}
                  className="form-control"
                  dateFormat="dd/MMMM/yyyy"
                  maxDate={new Date()}
                  onChange={(date) => setFromDate(date)}
                />
              </div>
              <div className="col-auto">
                <label className="mr-2">To</label>
                <DatePicker
                  selected={toDate}
                  className="form-control"
                  dateFormat="dd/MMMM/yyyy"
                  maxDate={new Date()}
                  onChange={(date) => setToDate(date)}
                />
              </div>
              <div className="col-auto text-right">
                <button
                  className="btn btn-primary btn-black"
                  onClick={() => {
                    getReports();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <h2 className="main-title">
          Reporting/Analytics{" - "}
          <span className="searched-time-wrap">
            <div className="badge badge-dark">
              {moment(searchFromDate).format("ll")}
            </div>
            {" to "}
            <div className="badge badge-dark">
              {moment(searchToDate).format("ll")}
            </div>
          </span>
        </h2>
        <div className="row mt-2">
          <div className="col-md-12">{getAnalyticData()}</div>
        </div>

        <h2 className="main-title mt-5">
          Funnel Tracking{" - "}
          <span className="searched-time-wrap">
            <div className="badge badge-dark">
              {moment(searchFromDate).format("ll")}
            </div>
            {" to "}
            <div className="badge badge-dark">
              {moment(searchToDate).format("ll")}
            </div>
          </span>
        </h2>
        <div className="row mt-2">
          <div className="col-md-12">{getFunnelData()}</div>
        </div>

        <h2 className="mt-5 main-title">
          By Player View{" - "}
          <span className="searched-time-wrap">
            <div className="badge badge-dark">
              {moment(searchFromDate).format("ll")}
            </div>
            {" to "}
            <div className="badge badge-dark">
              {moment(searchToDate).format("ll")}
            </div>
          </span>
        </h2>
        <div className="row by-player-result">
          {byPlayerReport.map((player: any, i) => {
            return (
              <ul className="main" key={i}>
                <li className="main">
                  {" "}
                  <small className="text-dimmed">Player: </small>{" "}
                  {player.playerName}
                </li>
                <ul className="sub">
                  {player.playerDayDetail.map((detail: any, i) => {
                    return (
                      <React.Fragment key={i}>
                        {detail.count ? (
                          <li>
                            {moment(detail.day).format("ll")}
                            {
                              <span className="number">
                                <small>Played</small> {detail.count}
                              </span>
                            }
                          </li>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </ul>
            );
          })}
        </div>

        <h2
          style={{ display: "flex", justifyContent: "space-between" }}
          className="main-title mt-5"
        >
          <div style={{ display: "flex", alignItems: "center", flex: "1" }}>
            Daily Report{" - "}
            <span
              style={{ marginLeft: "0.3rem" }}
              className="searched-time-wrap"
            >
              <div className="badge badge-dark">
                {moment(dailyReportSearchDate).format("ll")}
              </div>
            </span>
          </div>

          <div style={{ marginRight: "2rem" }}>
            <DatePicker
              selected={dailyReportSearchDate}
              className="form-control"
              dateFormat="dd/MMMM/yyyy"
              maxDate={new Date()}
              onChange={(date) => setDailyReportSearchDate(date)}
            />
          </div>
        </h2>

        <div
          style={{
            textDecoration: "none",
            display: "flex",
            margin: "0 0.1rem 1rem",
            justifyContent: "flex-end",
          }}
        >
          <CSVLink
            className="download-button"
            data={data}
            headers={reportingAndAnalyticsHeaders}
            filename={"Daily Report.csv"}
          >
            Download Daily Report
          </CSVLink>
        </div>
        <div style={{paddingBottom:"10px"}}>
        <button
              className={filter == "Dashbord" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("Dashbord");
                setGameType(2);
              }}
            >
              Dashbord
            </button>
            <button
              className={filter == "Single" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("Single");
                setGameType(2);
              }}
            >
              SinglePlayer
            </button>
            <button
              className={filter == "Multi" ? "btn btn-primary" : "btn"}
              onClick={() => {
                setFilter("Multi");
                setGameType(2);
              }}
            >
              MultiPlayer
            </button>
        </div>
        <div style={{ overflow: "scroll", minHeight: "15rem" }}>
          <ReactTooltip place="left" type="success" effect="solid" />
          <Table columns={columnsTable} data={data} />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
