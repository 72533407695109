import React, { Component, Fragment, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "assets/img/admin-icon.png";

export default function Sidebar() {
  const width = window.innerWidth;
  const sidebarBackground = {};

function clearlocalstorage()
{ localStorage.removeItem("jwt");
//localStorage.removeItem("Role");
//console.log("sidebar local",localStorage);
}

  return (
    <div id="sidebar" className="sidebar">
      <div className="sidebar-background" style={sidebarBackground} />
      <div className="logo">
        <a className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="logo_image" />
          </div>
        </a>
        <a className="simple-text logo-normal">
        Admin Panel{" "}
          {/* <small className="text-muted sidebar-sm-text">Admin Panel</small> */}
        </a>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          {/* active */}
          <li className={""}>
            <NavLink
              to={"dashboard"}
              className="nav-link"
              activeClassName="active"
            >
              <i className="" />
              <p>Dashboard</p>
            </NavLink>
          </li>

          <li className={""}>
            <NavLink
              to={"game-config"}
              className="nav-link"
              activeClassName="active"
            >
              <i className="" />
              <p>Game Config</p>
            </NavLink>
          </li>

          <li className={""}>
            <NavLink
              to={"rake-table"}
              className="nav-link"
              activeClassName="active"
            >
              <i className="" />
              <p>Rake Table</p>
            </NavLink>
          </li>
          {localStorage.getItem("Role") === "Admin" ? (
            <li className={""}>
              <NavLink
                to={"user-register"}
                className="nav-link"
                activeClassName="active"
              >
                <i className="" />
                <p>User Register</p>
              </NavLink>
            </li>
          ) : (
            <Fragment />
          )}
          <li className={""}>
            <NavLink
              to={"Login"}
              className="nav-link"
              activeClassName="active"
              onClick={() => clearlocalstorage()}
            >
              <i className="" />
              <p>Logout</p>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
