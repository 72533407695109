import React from "react";

interface ButtonProps {
    onClick?: CallableFunction;
    className?: string;
    children: any;
    isWorking?: boolean;
    type?: "button" | "submit" | "reset";
}

const Button = (props: ButtonProps) => {
    return renderButton();

    function renderButton() {
        let disabled = props.isWorking;
        let text = props.children;
        if (props.isWorking) {
            text = (
                <div className="spinner-border">
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }

        return (
            <button
                type={props.type ? props.type : "button"}
                disabled={disabled}
                onClick={() => {
                    props?.onClick?.();
                }}
                className={props.className}
            >
                {text}
            </button>
        );
    }
};

export default Button;
