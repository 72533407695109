/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import ApiService from "services/ApiService";
import Button from "../components/Button";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { handleErrors } from "services/HelperMethods";


const Login = (props) => {
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [show, setShow] = useState(false);
  const [qr, setqr] = useState();
  const [code, setcode] = useState();
  const [verifycode, setVerifycode] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const apiService = new ApiService();

   async function getAdminQR() {
    try {
      let data = { Username };
      let result = await apiService.getAdminQR(data);
      setcode(result.data[1]);
      setqr(result.data[0]);
    } catch (error) {
      handleErrors(error);
    }
  }

  async function tryverifyauth() {
    if (verifycode.length == 6) {
      setIsWorking(true);
      apiService.verifyAuth(
        verifycode,
        Username,
        async (data) => {
          setIsWorking(false);
          if (!data) {
            toast("Authentication Failed!");
          } else {
            toast("Authentication Key is Valid!");
            setVerifycode("");
            handleClose();

            let UserRole = 2;
            const TwoFactorAuthenticate = true;
            let data = {
              username: Username,
              password: Password,
              userRole: UserRole,
              twoFactorAuthenticate: TwoFactorAuthenticate,
            };
            let result = await apiService.updateuser(data);
            if (!result) {
              console.log("user updated",result);
            }
          }
        },
        (err) => {
          console.log("err", err);
          toast("Network Error!");
          setIsWorking(false);
        }
      );
    } else {
      toast("Authentication key must be 6 digits!");
    }
  }

  function tryLogin() {
    // if(Username=="admin" && Password=="aaaaaa")
    // {
    //   props.history.push("dashboard");
    //   console.log("login")
    // }
    
    if (!Username || !Password) {
      toast("Required username and password!");
      return;
    }

    setIsWorking(true);
    apiService.authenticate(
      Username,
      Password,
      (data) => {
        setIsWorking(false);
        if (data.error) {
          toast(data.error);
        } else {
          if (data.urole == "Admin") {
            props.history.push({
              pathname: "otp",
              state: {
                tags: Username,
              },
            });
          } else {
            if (data.auth == false) {
              getAdminQR();
              handleShow();
            } else {
              props.history.push({
                pathname: "otp",
                state: {
                  tags: Username,
                },
              });
            }
          }
        }
      },
      (err) => {
        console.log("err", err);
        toast("Network Error!");
        setIsWorking(false);
      }
    );
  }

  return (
    <div className="container-fluid login-wrapper">
      <div className="row login-row">
        <div className="col-md-6">
          <div className="card">
            <div className="header text-center">
              <h4 className="title">
                Hollywood Quiz - <strong>Login</strong>
              </h4>
              <p className="category"></p>
            </div>
            <form
              className="content"
              onSubmit={(e) => {
                e.preventDefault();
                tryLogin();
              }}
            >
              <div>
                <div className="form-group">
                  <label className="control-label">Username</label>
                  <input
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter username"
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                  />
                </div>
                <div className="form-group">
                  <label className="control-label">Password</label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                  />
                </div>
              </div>
              <div className="footer text-right">
                <div className="legend">
                  <Button
                    type="submit"
                    className="btn-fill btn-wd btn btn-black"
                    isWorking={isWorking}
                  >
                    Authenticate
                  </Button>
                </div>
              </div>
            </form>
            <Modal
              show={show}
              onHide={handleClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Two - Factor Authentication</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <div>
                  <p>
                    To use an authenticator app go through the following steps:
                  </p>
                  <ol className="list">
                    <li>
                      <p>
                        Google Authenticator for
                        <a
                          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en"
                          target="_blank"
                        >
                          {" "}
                          Android
                        </a>{" "}
                        and
                        <a
                          href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                          target="_blank"
                        >
                          {" "}
                          iOS
                        </a>
                        .
                      </p>
                    </li>
                    <li>
                      <p>
                        Scan the QR Code or enter below key into your two factor
                        authenticator app. Spaces and casing do not matter.
                      </p>

                      <div id="qr">
                        {/* <h3 style={{ display: "inline", marginRight: "110px" }}> {code}</h3> */}
                        <img src={qr} alt="qr" />
                      </div>
                    </li>
                    <li>
                      <p>
                        Once you have scanned the QR code or input the key
                        above, your two factor authentication app will provide
                        you with a unique code. Enter the code in the
                        confirmation box below.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <form>
                            <div className="form-group">
                              <div>
                                <label>
                                  Verification Code
                                  <span className="req">*</span>
                                </label>
                                <input
                                  onChange={(e) =>
                                    setVerifycode(
                                      (e.target.value = e.target.value
                                        .replace(/[^0-9]/g, "")
                                        .toLowerCase())
                                    )
                                  }
                                  placeholder="Enter Verification Code"
                                  className="form-control"
                                  value={verifycode}
                                />
                              </div>
                            </div>
                            <div>
                              <Button
                                type="submit"
                                isWorking={isWorking}
                                className="btn btn-primary"
                                onClick={(e) => {
                                  tryverifyauth();
                                }}
                              >
                                VERIFY
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
