import React, { createContext, useEffect, useState } from "react";

export const UserDetails = createContext([] as any[]);

export default function Store({ children }) {
    const [user, setUser] = useState(null);

    useEffect(()=>{

    },[user])
    return (
        <UserDetails.Provider value={[user, setUser]}>
            {children}
        </UserDetails.Provider>
    );
}
