import { toast } from "react-toastify";

export function handleErrors(err) {
    if (err?.response?.data?.error && !err?.response?.data?.error?.errorType) {
        showNotification(err?.response?.data?.error?.message, "error");
    } else if (err?.response?.data?.errors) {
        for (const [key, value] of Object.entries(err.response?.data?.errors)) {
            let title = key as any;
            let message = (value as any)[0] as any;
            showNotification(message, "error");
        }
    } else if (err?.response?.data?.error?.errorType) {
        showNotification(err?.response?.data?.error?.errorMessage, "error");
    } else {
        showNotification("Network error! try again", "error");
    }

    console.error(err, err.response);
}
export function showNotification(message, type: "success" | "error" | "info") {
    let tst =
        type == "success"
            ? toast.success
            : type == "error"
            ? toast.error
            : toast.info;
    tst(message);
}
