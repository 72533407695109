import React, { useEffect } from "react";
import { useState } from "react";
import ApiService from "services/ApiService";
import Button from "../components/Button";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";


const Login = (props) => {
  const [code, setCode] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const apiService = new ApiService();
  let { location } = useHistory();
 // console.log("uname2", location.state.tags);

  function redirectLogin() {
    props.history.push("login");
  }
  
  function tryLogin() {
    if (code == null || code.length != 6) {
      toast("Authentication key must be 6 digits!");
      return;
    } else {
      setIsWorking(true);
      apiService.verifyAuth(
        code,
        location.state.tags,
        (data) => {
          setIsWorking(false);
          if (!data) {
            toast("Authentication Failed!");
          } else {
            //console.log("ötp data",data);
            props.history.push("dashboard");
          }
        },
        (err) => {
          console.log("err", err);

          toast("Network Error!");
          setIsWorking(false);
        }
      );
    }

    setIsWorking(true);
  }

  return (
    <div className="container-fluid login-wrapper">
      <div className="row login-row">
        <div className="col-md-6">
          <div className="card">
            <div className="header text-center">
              <h4 className="title">
                Hollywood Quiz - <strong>Authenticator</strong>
              </h4>
              <p className="category"></p>
            </div>
            <form
              className="content"
              onSubmit={(e) => {
                e.preventDefault();
                tryLogin();
              }}
            >
              <div>
                <div className="form-group">
                  <label className="control-label">Authenticate Code</label>
                  <input
                    onChange={(e) =>
                      setCode(
                        (e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .toLowerCase())
                      )
                    }
                    placeholder="Enter Authenticate Code"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="footer text-right">
                <div className="legend">
                  <Button
                    type="button"
                    className="btn-fill btn-wd btn btn-black"
                    isWorking={isWorking}
                    onClick={redirectLogin}
                  >
                    Back
                  </Button>
                </div>
                <div className="legend" style={{ padding: "5px 5px" }}>
                  <Button
                    type="submit"
                    className="btn-fill btn-wd btn btn-black"
                    isWorking={isWorking}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
