import React from "react";
import { ToastContainer } from "react-toastify";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import Login from "views/Login";
import RakeTable from "views/Raketable";
import Otp from "views/Otp";
import UserRegister from "views/UserRegister";
import GameConfig from "views/GameConfig";
import Dashboard from "views/Dashboard";

export default function App() {
  return (
    <HashRouter>
      <ToastContainer />
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/otp" exact component={Otp} />
        <Route path="/rake-table" exact component={RakeTable} />
        <Route path="/user-register" exact component={UserRegister} />
        <Route path="/game-config" exact component={GameConfig} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/" render={() => <Redirect to="/login" />} />
      </Switch>
    </HashRouter>
  );
}
