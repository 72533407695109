import Button from "components/Button";
import AdminLayout from "layouts/AdminLayout";
import React, { useEffect, useState } from "react";
import ApiService from "services/ApiService";
import { handleErrors } from "services/HelperMethods";

export default function RakeTable() {
    const apiService = new ApiService();
    const [rakeList, setRakeList] = useState([] as any[]);
    const [isWorking, setIsWorking] = useState(false);
    useEffect(() => {
        getRakeTable();
    }, []);

    async function getRakeTable() {
        setIsWorking(true);
        try {
            let result = await apiService.getRakeTable();
            console.log("rake result",result)
            setRakeList(result.data);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }

    async function updateRake(rake) {
        setIsWorking(true);
        try {
            let result = await apiService.updateRakeTable(rake);
            console.log("update", result);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }

    return (
        <AdminLayout>
            <div className="rake-page">
                <h2>Rake table</h2>
                <ul>
                    {rakeList.map((rake, i) => {
                        return (
                            <li className="rake-table-list" key={i}>
                                <div className="form-group">
                                    <label>Total Players</label>
                                    <input
                                        type={"number"}
                                        onChange={(e) => {
                                            rake.totalPlayers = parseFloat(
                                                e.target.value
                                            );
                                            let rakeCopy = [...rakeList];
                                            setRakeList(rakeCopy);
                                        }}
                                        className="form-control"
                                        value={rake.totalPlayers}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Rake %</label>
                                    <input
                                        type={"number"}
                                        onChange={(e) => {
                                            rake.rake = parseFloat(
                                                e.target.value
                                            );
                                            let rakeCopy = [...rakeList];
                                            setRakeList(rakeCopy);
                                        }}
                                        className="form-control"
                                        value={rake.rake}
                                    />
                                </div>
                                <div className="form-group">
                                    <Button
                                        isWorking={isWorking}
                                        onClick={() => {
                                            updateRake(rake);
                                        }}
                                        className="btn btn-secondary"
                                    >
                                        Update
                                    </Button>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </AdminLayout>
    );
}
