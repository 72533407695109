import axios from "axios";

export default class ApiService {
    getAnalytics(data) {
        return this.ax().post("api/visitorRecords/analytics/", data);
    }

    getFunnelData(data) {
        return this.ax().post("api/visitorRecords/funnelTracking/", data);
    }

    playerCount(data) {
        return this.ax().post("api/visitorRecords/playerCount/", data);
    }

    totalGamePlayed(data) {
        return this.ax().post("api/visitorRecords/totalGamePlayed/", data);
    }

    getRakeTable() {
        return this.ax().get("api/RakeTables");
    }
    updateRakeTable(rake) {
        return this.ax().put("api/RakeTables", rake);
    }

    getEnvironmentConfig() {
        return this.ax().get("api/EnvironmentConfigs");
    }
    getAdminQR(data) {
        return this.ax().post("auth/genarateqr",data);
    }
    updateEnvironmentConfig(data) {
        return this.ax().put("api/EnvironmentConfigs", data);
    }

    createuser(data) {
        return this.ax().post("apiuser/User/adduser", data);
    }

    updateuser(data) {
        return this.ax().put("apiuser/User/updateuser", data);
    }

    getDailyReport(data) {
        return this.ax().post("api/visitorRecords/dailyReport/", data);
    }


    authenticate(username, password, callBack, errorCallback) {
        return this.ax()
            .post("auth/login", { username, password })
            .then((data: any) => {
                data = data.data;
                if (data.jwt) {
                    localStorage.setItem("jwt", data.jwt);
                    localStorage.setItem("Role", data.urole);
                    callBack(data);
                } else {
                    callBack(data);
                }
            })
            .catch(() => {
                errorCallback();
            });
    }
    verifyAuth(authkey,username,callBack, errorCallback) {
        return this.ax()
            .post("auth/verifyauth", {authkey,username})
            .then((data: any) => {
                data = data.data;
                if (data.response) { 
                    callBack(true);
                } else {
                    callBack(false);
                }
            })
            .catch(() => {
                errorCallback();
            });
    }
    ax() {
       const baseUlr ="https://admin.catmonocle.com";
       //const baseUlr ="https://localhost:44338";
        const jwt = localStorage.getItem("jwt");

        return axios.create({
            baseURL: baseUlr,
            timeout: 30000,
            headers: {
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
            },
        });
    }
}
