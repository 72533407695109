import React from "react";
import ReactDOM from "react-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";
import Store from "./store/Store";
import App from "App";

ReactDOM.render(
  <Store>
    <App />
  </Store>,
  document.getElementById("root")
);
