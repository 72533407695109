import React from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

export default function AdminLayout(props: { children }) {
    return (
        <div className="wrapper">
            <NotificationSystem />
            <Sidebar />
            <div id="main-panel" className="main-panel">
                <AdminNavbar {...props} />
                <div className="content border mt-4">{props.children}</div>
                <Footer />
            </div>
        </div>
    );
}
