import Button from "components/Button";
import AdminLayout from "layouts/AdminLayout";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import ApiService from "services/ApiService";
import { handleErrors } from "services/HelperMethods";

export default function GameConfig() {
    const apiService = new ApiService();
    const [gameConfig, setGameConfig] = useState(null as any);
    const [isWorking, setIsWorking] = useState(false);
    useEffect(() => {
        getGameConfig();
    }, []);

    async function getGameConfig() {
        try {
            let result = await apiService.getEnvironmentConfig();
            console.log("config", result.data);
            setGameConfig(result.data);
        } catch (error) {
            handleErrors(error);
        }
    }

    return (
        <AdminLayout>
            <div className="config-page">
                <h2>Game Config</h2>
                {gameConfig ? (
                    <div>
                        <div className="config-section">
                            <label>Game Start Time (Seconds)</label>
                            <input
                                onChange={(e) => {
                                    gameConfig.gameConfigMaxGameStartTime =
                                        e.target.value;
                                    setGameConfig({ ...gameConfig });
                                }}
                                className="form-control"
                                value={gameConfig?.gameConfigMaxGameStartTime}
                            />
                            <div className="mt-1 small">
                                The value 30 means it will take 30 seconds to
                                start another game
                            </div>
                        </div>
                        <div className="config-section">
                            <label>Min Total Players to start a game</label>
                            <input
                                onChange={(e) => {
                                    gameConfig.gameConfigMaxPlayerCountToStartGame =
                                        e.target.value;
                                    setGameConfig({ ...gameConfig });
                                }}
                                className="form-control"
                                value={
                                    gameConfig?.gameConfigMaxPlayerCountToStartGame
                                }
                            />
                            <div className="mt-1 small">
                                The value 2 means it at least need 2 players to
                                start the game
                            </div>
                        </div>
                        <div className="config-section">
                            <label>Number of questions per game</label>
                            <input
                                onChange={(e) => {
                                    gameConfig.gameConfigQuestionsPerGame =
                                        e.target.value;
                                    setGameConfig({ ...gameConfig });
                                }}
                                className="form-control"
                                value={gameConfig?.gameConfigQuestionsPerGame}
                            />
                            <div className="mt-1 small">
                                The value 10 means 10 questions asked in a
                                single game
                            </div>
                        </div>
                        {/* <div className="config-section">
                            <label>Debit Amount</label>
                            <select
                                className="form-control"
                                onChange={(e) => {
                                    gameConfig.catmBingoCurrencyMultiplyer =
                                        e.target.value;
                                    setGameConfig({ ...gameConfig });
                                }}
                                value={gameConfig?.catmBingoCurrencyMultiplyer}
                            >
                                <option value={0}>0 EUR</option>
                                <option value={1}>0.01 EUR</option>
                                <option value={10}>0.1 EUR</option>
                                <option value={100}>1 EUR</option>
                            </select>
                            <div className="mt-1 small">
                                This value determine player's debit amount.
                                <br />
                                In production this value should be 1 EUR.
                            </div>
                        </div> */}

                        <div className="config-section">
                            <label>Google Captcha Min Score</label>
                            <input
                                onChange={(e) => {
                                    gameConfig.gameConfigGoogleReCaptchaV3MinScoreToEnter =
                                        e.target.value;
                                    setGameConfig({ ...gameConfig });
                                }}
                                className="form-control"
                                value={
                                    gameConfig?.gameConfigGoogleReCaptchaV3MinScoreToEnter
                                }
                            />
                            <div className="mt-1 small">
                                Lower than this value can not enter.
                                <br />
                                value should be between 0 to 1
                            </div>
                        </div>

                        <div className="config-section">
                            <label>Game Rolling Gap (Seconds)</label>
                            <input
                                onChange={(e) => {
                                    gameConfig.gameRollingGapSeconds =
                                        e.target.value;
                                    setGameConfig({ ...gameConfig });
                                }}
                                className="form-control"
                                value={gameConfig?.gameRollingGapSeconds}
                            />
                            <div className="mt-1 small">
                                The changes will effect after you manually
                                stop/start the server again
                            </div>
                        </div>

                        <div>
                            <div className="badge badge-danger">
                                Danger Zone
                            </div>
                            <div>
                                <div className="config-section">
                                    <label>
                                        Bingo Server ID (Provided by Bingo)
                                    </label>
                                    <input
                                        onChange={(e) => {
                                            gameConfig.catmBingoServerId =
                                                e.target.value;
                                            setGameConfig({ ...gameConfig });
                                        }}
                                        className="form-control"
                                        value={gameConfig?.catmBingoServerId}
                                    />
                                </div>
                                <div className="config-section">
                                    <label>
                                        Catmonocle Bingo Server Wrapper URL
                                    </label>
                                    <input
                                        onChange={(e) => {
                                            gameConfig.catmBingoURL =
                                                e.target.value;
                                            setGameConfig({ ...gameConfig });
                                        }}
                                        className="form-control"
                                        value={gameConfig?.catmBingoURL}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="config-section text-right mt-1">
                            <Button
                                isWorking={isWorking}
                                className="btn btn-primary"
                                onClick={handleUpdate}
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Fragment />
                )}
            </div>
        </AdminLayout>
    );

    async function handleUpdate() {
        setIsWorking(true);
        try {
            let gc = { ...gameConfig };
            gc.catmBingoCurrencyMultiplyer = parseFloat(
                gc.catmBingoCurrencyMultiplyer
            );
            gc.gameConfigGoogleReCaptchaV3MinScoreToEnter = parseFloat(
                gc.gameConfigGoogleReCaptchaV3MinScoreToEnter
            );
            gc.gameConfigMaxGameStartTime = parseFloat(
                gc.gameConfigMaxGameStartTime
            );
            gc.gameConfigMaxPlayerCountToStartGame = parseFloat(
                gc.gameConfigMaxPlayerCountToStartGame
            );
            gc.gameConfigQuestionsPerGame = parseFloat(
                gc.gameConfigQuestionsPerGame
            );
            gc.gameRollingGapSeconds = parseInt(gc.gameRollingGapSeconds);
            let result = await apiService.updateEnvironmentConfig(gc);
            console.log(result);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }
}
