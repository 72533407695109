import React from "react";
import { useTable } from "react-table";
import Tippy from "@tippyjs/react";
import "../style.scss";

function Table({ columns, data }) {
  console.log(data, "daily");
  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  background: "#ffffffb5",
                  color: "black",
                  fontWeight: "bold",
                  minWidth: "6rem",
                  margin: "1rem",
                  padding: "0 1rem",
                  border: "solid 0.6px black",
                  borderRight: "solid 2px black",
                  borderLeft: "solid 2px black",
                  borderTop: "solid 2px black",
                  textAlign: "center",
                }}
              >
                <Tippy content={column.name}>
                  <span>{column.Header}</span>
                </Tippy>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
               
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "10px",
                      border: "solid 0.6px black",
                      borderRight: "solid 2px black",
                      borderLeft: "solid 2px black",
                      borderBottom: "solid 2px black",
                      background: "#fff",
                      position: "relative",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
